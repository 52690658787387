<script setup lang="ts">
import { useAuthStore } from "~/store/auth/auth.store";

const authStore = useAuthStore();

const { $Localize } = useNuxtApp();

const typedLocalize: any = $Localize;

onMounted(async () => {
  await authStore.fetchProfile();

  if (authStore.isLoggedIn()) {
    typedLocalize.setLanguage(authStore.user?.language);
  }
});
</script>

<template>
  <div class="details_background">
    <slot />
    <FeedbackContainer></FeedbackContainer>
  </div>
</template>

<style lang="scss">
.details_background {
  min-width: 100%;
  min-height: 100%;
  background: var(--white-main);
}
</style>
